/*Navbar*/

.navbar {
    width: 100%;
    
}

/*banner*/
.register-button {
    margin-right: auto;
    margin-left: auto;
    text-decoration: none;
    background-color: #5D7FF5;
    padding: 1.1rem;
    border-radius: 75%;
    color: white;
    font-weight: bold;
    margin-top: 15rem;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

/*Race Info*/
.race-info {
    font-family:'Inter', sans-serif;
    background-color: #5D7FF5;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 2rem;
    padding-right: 5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin-top: .5rem;
}

.race-info a {
    color: black;
    font-weight: bold;
}
.race-info p {
    color: white;
}

.race-info li {
    color: white;

}

.bold-list {
    font-weight: bold;
}
.bold-list div {
    text-align:center;
    margin-top: 1rem
}

.prize-list {
    display: flex;
    flex-direction: column;
    align-items:center;
}


.race-info h3 {
    font-weight: bold;
}

.race-info h4 {
    font-weight: bold;
}

.prize {
    font-weight:bolder;
    color:black;
    text-align: center;
    font-size: 1.5rem;
}
.register-prize {
    font-weight:bolder;
    color:black;
    text-align: center;
}

.prize-images img {
    margin-right: 1rem
}
/*Sponsors*/

.sponsor-title {
   display: flex; 
   align-items: center;
   padding-left: 6rem;
   font-family:'Inter', sans-serif;
   padding-top: 2rem;
}

.sponsor-title h3 {
    font-weight: bold;
}
.sponsor-title a {
    margin-left: 1rem;
}

.rhino h3 {
    margin-left: 2rem;
    font-weight: bold;
}

.rhino h4 {
    margin-left: 2rem;
}
.rhino-sponsors div {
    margin-bottom: 2rem;
    align-items: center;
}




.individual-sponsors {
    margin-left: 5rem;
    

}

.rhino-sponsors { 
text-align: center;}
   
.rhino-sponsors a {
    color: black;
    font-weight: bold;
    font-size: 1rem;
    font-family:'Inter', sans-serif;
}


/* Sticky Navbar */
#container {
    width: 100%;
}
.sticky-register-button {  
    margin-right: auto;
    margin-left: auto;
    text-decoration: none;
    background-color: #5D7FF5;
    padding: .75rem;
    border-radius: 75%;
    color: white;
    font-weight: bold;
    margin-left: 4rem;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);}

.sticky-navbar {
    display: flex;
    flex-direction:row;
    align-items: center;
    text-align: center;
    justify-content: center;
    position: fixed;
    top: 0;
    background: white;
    width: 100%;
    z-index: 1;
    
}
.under-banner {
    margin-top: 1rem;
    display: flex;
    flex-direction:row;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: white;
    width: 100%;
    z-index: 1;
}

.sticky-navbar-info {
    margin-left: 2rem;
    font-weight: bold;
}

.sticky-navbar-logo {
 width: 150px;
 height: 80px;   
}
/*Footer*/

.footer {
    text-align: center;
}

@media only screen and (max-width: 1024px){
    .rhino {
        display: flex;
        flex-direction: column;
        
           
    }

    
       
    .rhino-sponsors a, .construction a, .construction-sponsors2 a, .construction-sponsors3 a {
    margin-left: -3rem;
       
    }
    
    .construction-sponsors, .rhino-sponsors, .construction-sponsors2, .construction-sponsors3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        
    
    }


}
@media only screen and (max-width: 768px) {
  .register-button {
    padding: 1rem;
    margin-top: 6rem;
  }
  .race-info p {
    font-size: .75rem;
  }

  .race-info li {
    font-size: .75rem;
  }
  .sponsor-title {
    padding-left: 1rem;
    padding-top: 1rem;
  }
.sponsor-title h3 {
    font-size: 1.25rem;
}
.sponsor-title a {
    font-size: .75rem;
}

.footer {
    font-size: .75rem;
}
.individual-sponsors {
    margin-left: 0rem;
}

}    
@media only screen and (max-width: 40em) {
    .register-button{
        padding: .5rem;
        font-size: .75rem;
        margin-top: 3rem;
    }

    .sticky-navbar-logo {
        width: 100px;
        height: 53px;   
       }
      
    .sticky-navbar h5, .under-banner h5 {
        font-size: .6rem;
    }
    .sticky-navbar h6, .under-banner h6 {
        font-size: .5rem;
    }
    .sticky-register-button {
        padding: .5rem;
        font-size: .75rem;
        margin-left: 1rem;
    }
    .sticky-navbar-info {
        margin-left: .5rem;
    }
.race-info {
    width: 100%;
    padding-right: 2rem;
}


    .race-info h3 {
        font-size: 1rem;
    }
    
    .race-info h4 {
        font-size: .8rem;
    }
      }
.photo-slideshow {
    padding:56.25% 0 0 0;
    position:relative;
    margin: auto;
    height: fit-content;
}
.photo-iframe {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    
    
}